<template>
  <v-row>
    <v-col
      cols="12"
      class="mb-6"
    >
      <v-card>
        <v-card-title>
          Tags <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="newtag"
          >
            เพิ่ม Tags
          </v-btn>
        </v-card-title>
        <v-row class="ma-0 pb-1 px-2 align-center">
          <v-col>
            <v-text-field
              v-model="search"
              label="ค้นหา tag"
              dense
              :prepend-icon="icons.mdiMagnify"
              hide-details
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="ma-0 pb-1 px-2 align-center">
          <v-col>
            <v-data-table
              :search="search"
              :headers="headers"
              :items="tags"
              :items-per-page="25"
              class="elevation-1"
            >
              <!-- <template v-slot:[`item.status`]="{ item }">
                {{ getstatustext(item.status) }}
              </template> -->
              <template v-slot:[`item.action`]="{ item }">
                <!-- <v-btn
                  icon
                  color="info"
                  @click="editcontentgroup(item)"
                >
                  <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                </v-btn> -->
                <v-btn
                  icon
                  color="error"
                  @click="removetag(item._id)"
                >
                  <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Tags</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="currenttag.tag"
                  label="Tag"
                  dense
                  outlined
                  :rules="[v=> !!v || 'Required']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="success"
            @click="addtag"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {
  mdiPlus,
  mdiPencilOutline,
  mdiDeleteOutline,
  mdiClose,
  mdiCamera,
  mdiMagnify,
  mdiPlusBox,
  mdiMinusBox,
} from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiClose,
        mdiCamera,
        mdiMagnify,
        mdiPlusBox,
        mdiMinusBox,
      },
    }
  },
  data() {
    return {
      covertmp: null,
      thumbnailtmp: null,
      search: '',
      findcontenttitle: '',
      dialog: false,
      headers: [
        { text: 'tag', align: 'start', value: 'tag' },
        { text: 'Action', value: 'action', width: '100' },
      ],
      contentgroups: [],
      contentgroup: '',
      mainpage: {

      },
      tags: [],
      dialogmode: 'new',
      currenttag: {
        tag: '',
      },
    }
  },
  watch: {
    async findcontenttitle(val) {
      if (this.contents.length > 0) return
      const { data: { contents } } = await this.axios.get('/admin/contents/title', {
        params: { title: val },
      })
      this.contents = contents
    },
  },
  async created() {
    const { data } = await this.axios.get('/admin/mainpage')
    this.mainpage = data.website
    this.gettags()
  },
  methods: {
    async addtag() {
      await this.axios.post('/admin/tag', { tag: this.currenttag.tag })
      this.gettags()
      this.dialog = false
    },
    async removetag(_id) {
      await this.axios.delete('/admin/tag', { params: { _id } })
      this.gettags()
    },
    async gettags() {
      const { data } = await this.axios.get('/admin/tags')
      this.tags = data.tags
    },
    newtag() {
      this.dialog = true
    },
  },
}
</script>
